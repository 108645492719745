<template>
  <div class="form-container">
    <div class="meno">
      <div class="form-segment">
        <h1 class="form-title">Accedi</h1>
      </div>
      <form>
        <div class="form-field">
          <input v-model="user" class="form-input" type="text" placeholder="Nome utente" autocomplete="username" aria-label="Nome utente" @input="deleteErr" />
          <span v-if="usernameError" class="error-message">Il nome utente è obbligatorio</span>
        </div>
        <div class="form-field">
          <div class="input-group">
            <input :type="showPin ? 'number' : 'password'" v-model="pin" class="form-input" placeholder="PIN" autocomplete="current-password" aria-label="PIN" @input="validatePin" />
            <button @click="toggleShowPin" type="button" class="btn-show-pin">{{ showPin ? 'Nascondi' : 'Mostra' }}</button>
          </div>
          <span v-if="pinError" class="error-message">Il PIN è obbligatorio e deve essere un numero</span>
        </div>
        <button @click="login" class="btn btn-red" :disabled="loading">
          <span v-if="loading">Loading...</span>
          <span v-else>Login</span>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie'; // Importa la libreria js-cookie

export default {
  name: 'Login',
  data() {
    return {
      user: '',
      pin: '',
      showPin: false,
      usernameError: false,
      pinError: false,
      loading: false,
    };
  },
  methods: {
    clearInput() {
      this.user = ''; // Resetta il campo user
      this.pin = ''; // Resetta il campo pin
      this.usernameError = false; // Resetta l'errore username
      this.pinError = false; // Resetta l'errore pin
    },
    deleteErr() {
      this.usernameError = false;
    },
    toggleShowPin() {
      this.showPin = !this.showPin;
    },
    validatePin(event) {
      const value = event.target.value;
      if (!/^\d*$/.test(value)) {
        this.pin = this.pin.slice(0, -1);
      } else {
        this.pinError = false;
      }
    },
    async login() {
      this.usernameError = !this.user;
      this.pinError = !this.pin || isNaN(this.pin);
      if (!this.usernameError && !this.pinError) {
        this.loading = true;
        try {
          const sanitizedUser = this.user.trim();
          const sanitizedPin = this.pin.trim();

          // Effettua la richiesta API di login
          const response = await axios.post('https://sincrocoop.it/api/login', {
            user: sanitizedUser,
            pin: sanitizedPin
          });

          // Se il login ha successo
          if (response.data && response.data.token) {
            //alert('Login effettuato con successo');

            // Salvataggio del token come cookie sicuro
            Cookies.set('token', response.data.token, {
              expires: 1
            });

            Cookies.set('user', sanitizedUser, {
              expires: 1
            });

            // Reindirizza alla pagina home o alla pagina richiesta originariamente
            this.$router.push(this.$route.query.redirect || '/home');
          } else {
            alert('Credenziali non valide');
            this.clearInput();
          }
        } catch (error) {
          if(error.response?.data == "Utente non trovato"){
            alert(error.response?.data);
            this.clearInput();
          } if(error == "AxiosError: Network Error"){
            alert("Connessione persa, verifica la connessione");
          } else{
            alert('Errore durante il login: ' + (error.response?.data || error || 'Errore sconosciuto'));
          }
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>

  
  <style lang="scss" scoped>
  $ruler: 16px;
  $color-argeovilla: #991520;
  $color-red: #AE1100;
  $color-bg: #EBECF0;
  $color-shadow: #BABECC;
  $color-white: #FFF;
  
  .form-container {
    padding: $ruler;
    width: $ruler*20;
    margin: 0 auto;
  }

  .meno{
    margin-left: -37px!important;
    margin-right: 37px;
  }
  
  .form-segment {
    padding: $ruler*2 0;
    margin-top: $ruler;
    text-align: center;
  }
  
  @import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@700&display=swap');
  
  .form-title {
    font-family: 'Roboto Slab', serif;
    font-size: $ruler * 2;
    color: $color-red;
    margin-bottom: $ruler * 0.1;
  }
  
  .error-message {
    color: $color-red;
    font-weight: bold;
    font-size: 14px;
    margin-top: 5px;
  }
  
  .btn,
  .form-input {
    border: 0;
    outline: 0;
    font-size: $ruler;
    border-radius: $ruler*20;
    padding: $ruler;
    text-shadow: 1px 1px 0 $color-shadow;
  }
  
  .form-field, .btn{
    display: block;
    margin-top: $ruler;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border: 0;
    outline: 0;
    font-size: $ruler;
    border-radius: $ruler*20;
    text-shadow: 1px 1px 0 $color-shadow;
  }
  
  .form-input {
    margin-right: $ruler/2;
    box-shadow: inset 2px 2px 5px $color-shadow, inset -5px -5px 10px $color-shadow;
    width: 100%;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
    background-color: $color-bg;
  
    &:focus {
      box-shadow: inset 1px 1px 2px $color-shadow, inset -1px -1px 2px $color-white;
      border: 1px solid $color-argeovilla;
    }
  }
  
  .btn {
    color: #61677C;
    background-color: $color-argeovilla;
    font-weight: bold;
    box-shadow: -1px -1px 20px $color-shadow, 1px 1px 20px $color-shadow;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    font-weight: 600;
  
    &:hover {
      box-shadow: -2px -2px 5px $color-white, 2px 2px 5px $color-white;
    }
  
    &:active {
      box-shadow: inset 1px 1px 2px $color-bg, inset -1px -1px 2px $color-bg;
    }
  
    &:disabled {
      background-color: $color-shadow;
      cursor: not-allowed;
    }
  
    .icon {
      margin-right: $ruler/2;
    }
  
    &.btn-unit {
      border-radius: $ruler/2;
      line-height: 0;
      width: $ruler*3;
      height: $ruler*3;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin: 0 $ruler/2;
      font-size: $ruler*1.2;
  
      .icon {
        margin-right: 0;
      }
    }
  
    &.btn-red {
      color: $color-white;
      margin-top: 50px;
    }
  }
  
  .input-group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  
    .form-field {
      margin: 0;
      flex: 1;
    }
  }
  
  .btn-show-pin {
    margin-left: $ruler / 2;
    background: none;
    border: none;
    cursor: pointer;
    font-size: $ruler;
    color: $color-red;
  
    &:focus {
      outline: none;
    }
  }
  </style>
  