import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue';
import HomeView from '../views/HomeView.vue'
import ClientsView from '../views/ClientsView.vue'
import QuotesView from '../views/QuotesView.vue'
import store from '../store'; // Assicurati di importare lo store qui
import Cookies from 'js-cookie'; // Assicurati di importare i cookie qui


const routes = [
  {
    path: '/login',
    component: LoginView
  },
  {
    path: '/',
    component: () => import('../layouts/Header&Footer.vue'),
    children: [
      { path: 'home', component: HomeView, meta: { requiresAuth: true, requiredLevel: 1 } },
      { path: 'appointments', name: 'appointments', component: () => import('../views/AppointmentsView.vue'), meta: { requiresAuth: true, requiredLevel: 3 } },
      { path: 'clients', name: 'clients', component: ClientsView, meta: { requiresAuth: true, requiredLevel: 2 } },
      { path: 'quotes', name: 'quotes', component: QuotesView, meta: { requiresAuth: true, requiredLevel: 2 } },
      { path: 'quotesd', name: 'QuoteDetail', component: () => import('../views/QuoteDetailView.vue'), meta: { requiresAuth: true, requiredLevel: 2 } },
      { path: 'quotesd/:id', name: 'QuoteDetailID', component: () => import('../views/QuoteDetailView.vue'), meta: { requiresAuth: true, requiredLevel: 2 } },
      { path: 'quotedetailread/:id', name: 'quotedetailread', component: () => import('../views/QuoteDetailRead.vue'), meta: { requiresAuth: true, requiredLevel: 2 } },
      { path: 'client/:id', name: 'ClientDetails', component: () => import('../views/ClientView.vue'), meta: { requiresAuth: true, requiredLevel: 2 } },
      // Aggiungi altre route protette qui
    ],
    meta: { requiresAuth: true }, // Aggiungi il meta campo per indicare che queste pagine richiedono autenticazione
    redirect: '/home',
  },
  {
    path: '/sharedquote/:shareId',
    name: 'SharedQuote',
    component: () => import('../views/SharedQuoteView.vue'),
    meta: { public: true }
  },  
  {
    path: '/:pathMatch(.*)*',
    redirect: '/login' // Qualsiasi altra route non trovata verrà reindirizzata al login
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Guarda globale per controllare l'autenticazione
router.beforeEach((to, from, next) => {
  
  const token = Cookies.get('token');
  const level = parseInt(Cookies.get('level'), 10);
  const user = Cookies.get('user');

  // Se l'utente è "marcello", consentire solo l'accesso a /quotes e alle pagine di visualizzazione in sola lettura
  if (user === 'marcello') {
    // Controlla se il path non è /quotes e non inizia con /quotedetailread
    if (!to.path.startsWith('/quotes') && !to.path.startsWith('/quotedetailread')) {
      // In questo esempio, consideriamo che tutte le rotte che non siano /quotes o /quotedetailread non siano per marcello
      next('/quotes');
      return;
    }
  }

  // Se la rotta è marcata come pubblica, non reindirizzare
  if (to.matched.some(record => record.meta.public)) {
    next();
    return;
  }
  
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!token) {
      next({
        path: '/login',
        query: { redirect: to.fullPath } // Salva la pagina che l'utente voleva visitare
      });
    } else {
      if (to.meta.requiredLevel && level < to.meta.requiredLevel) {
        alert('Non hai i permessi per accedere a questa pagina.');
        next({path: '/login'});
      }else{
        next();
      }
    }
  } else {
    if (token) {
      next('/home');
    }else{
      next(); // Assicurati di chiamare `next()` per procedere con la navigazione
    }
  }
});

export default router