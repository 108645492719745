<template>
  <div class="appointments-page">
    <!-- Intestazione Fissa con Riepilogo del Giorno -->
    <div class="header">
      <h1>Appuntamenti di Oggi</h1>
      <p>{{ formattedDate }}</p>
    </div>

    <!-- Sezione di Filtraggio (resta solo il link per tutti gli appuntamenti) -->
    <div class="filter-bar">
      <a href="/appointments">Tutti gli Appuntamenti</a>
    </div>

    <!-- LISTA APPUNTAMENTI (solo il giorno di oggi) -->
    <div class="appointments-list">
      <!-- Se non ci sono appuntamenti per oggi -->
      <div v-if="filteredAppointmentsList.length === 0" class="no-appointments">
        Non ci sono appuntamenti per oggi.
      </div>

      <!-- Altrimenti mostro gli appuntamenti di oggi -->
      <div
        v-else
        v-for="(appointment, index) in filteredAppointmentsList"
        :key="appointment._id || index"
        class="appointment-card"
        @click="openAppointmentModal(appointment)"
      >
        <div class="appointment-info">
          <h2>{{ appointment.clientName }}</h2>
          <p><strong>Indirizzo:</strong> {{ appointment.address }}</p>
          <p><strong>Data/Orario:</strong> {{ formatDate(appointment.dateTime) }}</p>
          <p><strong>Note:</strong> {{ appointment.noteSopralluogo }}</p>
        </div>
        <br>
        <!-- Azioni rapide -->
        <div class="appointment-actions">
          <!-- Apri Mappa -->
          <button @click.stop="openMap(appointment.address)" class="btn-map">
            <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92.3 132.3">
              <path fill="#1a73e8" d="M60.2 2.2C55.8.8 51 0 46.1 0 32 0 19.3 6.4 10.8 16.5l21.8 18.3L60.2 2.2z"/>
              <path fill="#ea4335" d="M10.8 16.5C4.1 24.5 0 34.9 0 46.1c0 8.7 1.7 15.7 4.6 22l28-33.3-21.8-18.3z"/>
              <path fill="#4285f4" d="M46.2 28.5c9.8 0 17.7 7.9 17.7 17.7 0 4.3-1.6 8.3-4.2 11.4 0 0 13.9-16.6 27.5-32.7-5.6-10.8-15.3-19-27-22.7L32.6 34.8c3.3-3.8 8.1-6.3 13.6-6.3"/>
              <path fill="#fbbc04" d="M46.2 63.8c-9.8 0-17.7-7.9-17.7-17.7 0-4.3 1.5-8.3 4.1-11.3l-28 33.3c4.8 10.6 12.8 19.2 21 29.9l34.1-40.5c-3.3 3.9-8.1 6.3-13.5 6.3"/>
              <path fill="#34a853" d="M59.1 109.2c15.4-24.1 33.3-35 33.3-63 0-7.7-1.9-14.9-5.2-21.3L25.6 98c2.6 3.4 5.3 7.3 7.9 11.3 9.4 14.5 6.8 23.1 12.8 23.1s3.4-8.7 12.8-23.2"/>
            </svg>
          </button>
          <!-- Chiama Cliente -->
          <button @click.stop="callClient(appointment.cell)" class="btn-call">
            <svg width="20" height="20" fill="white" viewBox="0 0 24 24">
              <path
                d="M6.62 10.79a15.053 15.053 0 006.59 6.59l2.2-2.2a1 
                   1 0 011.05-.24 10.99 10.99 0 003.46.55 1 1 
                   0 011 1v3.15a1 1 0 01-1 1C9.49 21 3 14.51 
                   3 6.5a1 1 0 011-1h3.15a1 1 0 011 1c0 
                   1.2.19 2.39.55 3.46.13.37.05.79-.24 1.08l-2.19 
                   2.25z"
              />
            </svg>
          </button>
          <!-- Apri Preventivo -->
          <button @click.stop="openQuote(appointment.idQuote)" class="btn-quote">
            <svg width="20" height="20" fill="white" viewBox="0 0 24 24">
              <path
                d="M14 2H6c-1.1 0-2 .9-2 2v16c0 
                   1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8l-6-6zm2 
                   17H8v-2h8v2zm0-4H8v-2h8v2zm-3-6V3.5L18.5 
                   9H13z"
              />
            </svg>
          </button>
          <!-- Elimina Appuntamento -->
          <button @click.stop="deleteAppointment(appointment._id)" class="btn-delete">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="white" aria-hidden="true" >
              <path d="M18.3 5.71a1 1 0 0 0-1.41 0L12 10.59 7.12 5.71a1 1 0 1 0-1.41 1.41L10.59 12l-4.88 4.88a1 1 0 1 0 1.41 1.41L12 13.41l4.88 4.88a1 1 0 1 0 1.41-1.41L13.41 12l4.88-4.88a1 1 0 0 0 0-1.41z"/>
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!-- MODAL DETTAGLI APPOINTMENT -->
    <div v-if="showAppointmentModal" class="modal-backdrop">
      <div class="modal">
        <h2>Dettagli Appuntamento</h2>

        <div class="modal-appointment-details">
          <div class="info-line">
            <label>Cliente:</label>
            <span>{{ selectedAppointment?.clientName }}</span>
          </div>
          <div class="info-line">
            <label>Indirizzo:</label>
            <span>{{ selectedAppointment?.address }}</span>
          </div>
          <div class="info-line">
            <label>Data/Orario:</label>
            <span>{{ formatDate(selectedAppointment?.dateTime) }}</span>
          </div>
          <div class="info-line">
            <label>Note:</label>
            <span>{{ selectedAppointment?.noteSopralluogo }}</span>
          </div>
        </div>

        <div class="actions appointment-actions-modal">
          <button :disabled="!selectedAppointment?.address" @click="openMap(selectedAppointment?.address)">
            Apri Mappa
          </button>
          <button :disabled="!selectedAppointment?.cell" @click="callClient(selectedAppointment?.cell)">
            Chiama
          </button>
          <button :disabled="!selectedAppointment?.idQuote" @click="openQuote(selectedAppointment?.idQuote)">
            Apri Preventivo
          </button>
          <button @click="deleteAppointment(selectedAppointment?._id)">
            Elimina
          </button>
        </div>

        <div class="actions">
          <button class="close-button" @click="closeAppointmentModal">Chiudi</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Chart from 'chart.js/auto';

export default {
  data() {
    return {
      appointments: [],  // conterrà gli appuntamenti recuperati via API
      selectedDate: new Date().toISOString().split('T')[0], // per filtrare su "oggi"

      // Modale dettaglio
      showAppointmentModal: false,
      selectedAppointment: null,

      // Quote & grafici
      quotes: [],
      statusCounts: {
        approvato: 0,
        attesa: 0,
        rifiutato: 0,
        terminato: 0,
      },
      quotesPerClient: {},
    };
  },
  computed: {
    // Data in formato leggibile (es: lunedì 26 gennaio 2025)
    formattedDate() {
      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
      return new Date().toLocaleDateString('it-IT', options);
    },
    // Filtra gli appuntamenti per "Oggi"
    filteredAppointmentsList() {
      let today = new Date(this.selectedDate);
      today.setHours(today.getHours() + 1);
      return this.appointments.filter((appt) => {
        if (!appt.dateTime) return false;
        const apptDate = new Date(appt.dateTime);
        return (
          apptDate.getFullYear() === today.getFullYear() &&
          apptDate.getMonth() === today.getMonth() &&
          apptDate.getDate() === today.getDate()
        );
      });
    },
  },
  mounted() {
    // Carichiamo gli appuntamenti reali e i preventivi
    this.fetchAppointments();
    this.fetchQuotes();
  },
  methods: {
    // Recupero appuntamenti dal backend
    async fetchAppointments() {
      try {
        const API_BASE_URL = 'https://sincrocoop.it/api/appointments';
        // Se il tuo backend ha una route differente, adegua qui.
        // Esempio: axios.get(`${API_BASE_URL}`) se /api/appointments è già la route corretta.
        const response = await axios.get(`${API_BASE_URL}/appointments`);
        this.appointments = response.data;
      } catch (error) {
        console.error('Errore durante il fetch degli appuntamenti:', error);
      }
    },

    // Mostra modal dettaglio
    openAppointmentModal(appointment) {
      this.selectedAppointment = appointment;
      this.showAppointmentModal = true;
    },
    // Chiudi modal dettaglio
    closeAppointmentModal() {
      this.selectedAppointment = null;
      this.showAppointmentModal = false;
    },

    // Format date ( gg/mm/aaaa hh:mm )
    formatDate(dateString) {
      if (!dateString) return '';
      const date = new Date(dateString);
      return date.toLocaleString('it-IT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    },

    // Pulsante: Apri mappa
    openMap(address) {
      if (!address) return;
      const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent("Genova GE, " + address)}`;
      window.open(url, '_blank');
    },
    // Pulsante: Chiama cliente
    callClient(cellNumber) {
      if (!cellNumber) return;
      window.open(`tel:${cellNumber}`);
    },
    // Pulsante: Apri Preventivo
    openQuote(idQuote) {
      if (!idQuote) return;
      window.location.pathname = `/quotesd/${idQuote}`;
    },
    // Pulsante: Elimina
    async deleteAppointment(appointmentId) {
      const confirmDel = confirm('Vuoi davvero eliminare questo appuntamento?');
      if (!confirmDel) return;

      try {
        const API_BASE_URL = 'https://sincrocoop.it/api/appointments';
        await axios.delete(`${API_BASE_URL}/appointments/${appointmentId}`);
        // Rimuovi localmente
        this.appointments = this.appointments.filter(a => a._id !== appointmentId);
        // Chiudi la modale se era aperta
        this.closeAppointmentModal();
      } catch (error) {
        console.error('Errore cancellazione appuntamento:', error);
      }
    },

    // ---- Metodi per grafici e quote ----
    fetchQuotes() {
      axios
        .get('https://sincrocoop.it/api/quotes/list')
        .then((response) => {
          this.quotes = response.data;
          this.processQuotesData();
          this.renderCharts();
        })
        .catch((error) => {
          console.error('Errore durante il recupero dei preventivi:', error);
        });
    },
    processQuotesData() {
      // Resetta i contatori
      this.statusCounts = {
        approvato: 0,
        attesa: 0,
        rifiutato: 0,
        terminato: 0,
      };
      this.quotesPerClient = {};

      this.quotes.forEach((quote) => {
        // Conta gli status
        if (this.statusCounts[quote.status] !== undefined) {
          this.statusCounts[quote.status]++;
        }
        // Conta i preventivi per cliente
        const clientName = quote.client?.name || 'Sconosciuto';
        if (this.quotesPerClient[clientName]) {
          this.quotesPerClient[clientName]++;
        } else {
          this.quotesPerClient[clientName] = 1;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
/* Stili di base per la pagina */
/* ---- scrollbar ---- */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #aaa;
}
::-webkit-scrollbar-thumb {
  background: #AE1100;
}
::-webkit-scrollbar-thumb:hover {
  background: #991520;
}

.appointments-page {
  padding: 20px 10px 20px 10px;
  max-width: 1200px;
  margin: auto;
  font-family: 'Roboto', sans-serif;
  background-color: #f9f9f9;
  min-height: 100vh;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}
.header h1 {
  color: #991520;
  font-size: 24px;
  margin-bottom: 5px;
}
.header p {
  color: #666;
  font-size: 16px;
}

.filter-bar {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-around;
}
.filter-bar a {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #991520;
  text-decoration: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}
.filter-bar a:hover {
  background-color: #7e111c;
}

.no-appointments {
  background-color: #fff6f6;
  border: 1px solid #ffd6d6;
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  color: #555;
  margin-bottom: 15px;
  font-style: italic;
}

/* Lista appuntamenti di oggi */
.appointments-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 100vh;
  overflow-y: auto;
}
.appointment-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin-right: 10px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
}
.appointment-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.appointment-info {
  max-width: 70%;
}
.appointment-info h2 {
  color: #333;
  margin: 0 0 8px;
  font-size: 18px;
}
.appointment-info p {
  color: #555;
  font-size: 14px;
  margin: 4px 0;
}

.appointment-actions {
  display: flex;
  gap: 10px;
}
.appointment-actions button {
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  transition: background-color 0.3s;
  text-decoration: none;
}

.actions, .actions button{
  margin-left: 3px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  padding: 5px;
  color: #ffffff!important
}

.btn-map {
  background-color: #fff; /* bianco */
  box-shadow: 0px 5px 5px #dedede;
}
.btn-map:hover {
  transform: scale(1.2);
}
.btn-call {
  background-color: #17a2b8; /* azzurro */
}
.btn-call:hover {
  transform: scale(1.2);
  background-color: #138496;
}
.btn-quote {
  background-color: #007bff; /* blu */
}
.btn-quote:hover {
  transform: scale(1.2);
  background-color: #0056b3;
}
.btn-delete {
  background-color: #dc3545; /* verde */
}
.btn-delete:hover {
  transform: scale(1.2);
  background-color: #c82333;
}
button:disabled {
  background-color: #ccc !important;
  cursor: not-allowed !important;
  opacity: 0.6;
}

/* MODAL DETTAGLI APPOINTAMENTO */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.35);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.modal {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px 25px;
  min-width: 350px;
  max-width: 500px;
  box-shadow: 0 10px 25px rgba(0,0,0,0.2);
  animation: fadeInModal 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
@keyframes fadeInModal {
  from { opacity: 0; transform: translateY(-10px); }
  to   { opacity: 1; transform: translateY(0); }
}
.modal h2 {
  margin: 0;
  font-size: 20px;
  color: #991520;
  margin-bottom: 5px;
}
.modal-appointment-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.modal-appointment-details .info-line {
  display: flex;
  gap: 8px;
}
.modal-appointment-details .info-line label {
  width: 90px;
  font-weight: bold;
  color: #333;
}
.modal-appointment-details .info-line span {
  flex: 1;
  color: #555;
}

.appointment-actions-modal button {
  border-radius: 4px;
  font-size: 13px;
  padding: 6px 12px;
  background-color: #0077cc;
}
.appointment-actions-modal button:hover {
  background-color: #005fa3;
}
.appointment-actions-modal button:nth-child(4) { /* Elimina */
  background-color: #dc3545;
}
.appointment-actions-modal button:nth-child(4):hover {
  background-color: #c82333;
}
.close-button {
  background-color: #666;
  border-radius: 4px;
}
.close-button:hover {
  background-color: #555;
}

/* SEZIONE GRAFICI */
.charts-section {
  display: none;
  /*display: flex;*/
  flex-wrap: wrap;
  justify-content: center;
  margin: 40px auto;
  gap: 20px;
}
.chart-container {
  width: 45%;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
.chart-container h2 {
  margin-bottom: 20px;
  color: #333;
  font-size: 18px;
}
.chart-container canvas {
  max-width: 100%;
  height: 250px;
}
@media only screen and (max-width: 700px) {
  .appointment-card{
    flex-direction: column;
  }
  .appointment-info {
    max-width: 100%;
    text-align: center;
  }
}

</style>
